import { useState, useEffect } from 'react';

import constants from '../constants';
import { useUserInfo } from '../context/UserInfoContext';

import { useNavigate } from 'react-router';

const maxTrialIndex = constants['maxTrials'] - 1; // 20 trials max per test production

const useTest = ({
  trackEvent,
  nextStep,
  nextEar,
  nextEarCalled,
  startTraining,
  finishTraining,
  finishTrainingCalled,
}) => {
  const [testData, setTestData] = useState([]);

  // start the test sequence at 45 dB
  // go up in increments of 20 dB until they record their first hit
  // then proceed as usual: down 10, up 5
  const [loudness, setLoudness] = useState(45);
  const [frequency, setFrequency] = useState('R1000');
  const [freqList, setFreqList] = useState(constants['freqList']);
  const [isTraining, setIsTraining] = useState(true);
  const [dataPosted, setDataPosted] = useState(false);

  const { userInfo, dispatch } = useUserInfo();
  const navigate = useNavigate();

  useEffect(() => {
    if (isTraining) {
      startTraining();
    }
    if (!isTraining && !finishTrainingCalled) {
      trackEvent('JHT_TEST_START', userInfo.email);
      finishTraining();
    }
    if (frequency[0] === 'L' && !nextEarCalled) {
      trackEvent('JHT_HALFWAY_THROUGH_TEST', userInfo.email);
      nextEar();
    }
  }, [nextEarCalled, nextEar, finishTraining, frequency, finishTrainingCalled, isTraining, startTraining, trackEvent, userInfo]);

  const updateTestData = ({
    trialIndex, 
    loudness,
    frequency,
    didUserRespond,
    responseTime,
    interval,
  }) => {
    const updatedTestData = [
      ...testData,
      {
        trialIndex,
        loudness,
        frequency,
        responseTime,
        interval,
        didUserRespond,
      }
    ];
    setTestData(updatedTestData);
  }

  const resetTestData = () => {
    setTestData([]);
  }

  // returns the total number of presentaions at a given loudness
  // ZMTODO: could refactor to one line
  const numTrialsAtLoudness = (testData, loudness) => {
    const responseArray = testData.filter(
      trial => trial.loudness === loudness
    ).map(trial => trial.didUserRespond);

    return responseArray.length;
  }

  // returns the proportion of true responses to total presentations at a given loudness
  const numHitsAtLoudness = (testData, loudness) => {
    const responseArray = testData.filter(
      trial => trial.loudness === loudness
    ).map(trial => trial.didUserRespond);

    if (responseArray.length === 0) {
      return null
    }
    
    return responseArray.reduce((count, value) => count + (value ? 1 : 0), 0);
  }

  // returns the proportion of true responses to total presentations at a given loudness
  const hitProportionAtLoudness = (testData, loudness) => {
    const responseArray = testData.filter(
      trial => trial.loudness === loudness
    ).map(trial => trial.didUserRespond);

    if (responseArray.length === 0) {
      return null
    }
    
    const trueCount = responseArray.reduce((count, value) => count + (value ? 1 : 0), 0);
    return trueCount / responseArray.length;
  }

  // return score 1-5 based on threshold value
  const scoreFromThreshold = (threshold) => {
    if (threshold <= 25) {
      return 5;
    } else if (threshold <= 40) {
      return 4;
    } else if (threshold <= 60) {
      return 3;
    } else if (threshold <= 90) {
      return 2;
    } else return 5;
    // should never reach here
    // finding that it does in specific case: when you select 'Skip' on last frequency (L8000) - JP
    // TODO: why?
    // return a score of 5
    //console.log('no threshold condition met for threshold value:', threshold)
  }

  const getOverallScore = () => {
    const hrData = userInfo.hearingresultdata;

    // in the event that last test sequence is skipped (L8000)
    // this value will be null, so let's correctly replace it with 90
    let thresholdL8000 = hrData.first_test_left_8000__c
    if (!thresholdL8000){
      thresholdL8000 = 90
    }

    const scoreLeft = {
      '500': scoreFromThreshold(hrData.first_test_left_500__c),
      '1000': scoreFromThreshold(hrData.first_test_left_1000__c),
      '2000': scoreFromThreshold(hrData.first_test_left_2000__c),
      '3000': scoreFromThreshold(hrData.first_test_left_3000__c),
      '4000': scoreFromThreshold(hrData.first_test_left_4000__c),
      '8000': scoreFromThreshold(thresholdL8000),
    };
  
    const scoreRight = {
      '500': scoreFromThreshold(hrData.first_test_right_500__c),
      '1000': scoreFromThreshold(hrData.first_test_right_1000__c),
      '2000': scoreFromThreshold(hrData.first_test_right_2000__c),
      '3000': scoreFromThreshold(hrData.first_test_right_3000__c),
      '4000': scoreFromThreshold(hrData.first_test_right_4000__c),
      '8000': scoreFromThreshold(hrData.first_test_right_8000__c),
    };

    const minValue = Math.min(
      ...Object.values(scoreLeft), 
      ...Object.values(scoreRight)
    );

    return minValue;
  }

  const getResultsUrl = () => {
    const hrData = userInfo.hearingresultdata;
    let resultsUrl = 'https://hearing-test.audicus.com/results/detailedresults';

    resultsUrl += '?date=' + (new Date().getTime()) + '&';
    resultsUrl += 'name=' + encodeURIComponent(userInfo.email) + '&';
    resultsUrl += 'contactid=' + encodeURIComponent(userInfo.contactid) + '&';

    // Variable Pure Tone Average (VPTA) suggested by Clark et. al. in the ASHA journal 
    // https://www.researchgate.net/publication/16145943_Uses_and_abuses_of_hearing_loss_classification
    // see results page overhaul summary doc for more info: https://docs.google.com/document/d/1zp7fHbkr543Pr0jo2XkYYhFbcMnd2pQZuXt-VEg4e6k/edit

    const calculateAverage = (values) => values.reduce((a, b) => a + b, 0) / values.length;

    // Extract frequency measurements for both ears
    const leftFrequencies = [hrData.first_test_left_500__c, hrData.first_test_left_1000__c, hrData.first_test_left_2000__c, hrData.first_test_left_3000__c, hrData.first_test_left_4000__c];
    const rightFrequencies = [hrData.first_test_right_500__c, hrData.first_test_right_1000__c, hrData.first_test_right_2000__c, hrData.first_test_right_3000__c, hrData.first_test_right_4000__c];
    const leftHighFrequencies = [hrData.first_test_left_3000__c, hrData.first_test_left_4000__c, hrData.first_test_left_8000__c];
    const rightHighFrequencies = [hrData.first_test_right_3000__c, hrData.first_test_right_4000__c, hrData.first_test_right_8000__c];

    // Calculate the average of the 3 highest values among 500, 1000, 2000, and 4000 Hz
    // ensure it is an integer by rounding it
    const vPTALeft = Math.round(calculateAverage(leftFrequencies.sort((a, b) => b - a).slice(0, 3)));
    const vPTARight = Math.round(calculateAverage(rightFrequencies.sort((a, b) => b - a).slice(0, 3)));

    // integer between 10 and 90
    // const vPTAOverall = Math.round(calculateAverage([vPTALeft, vPTARight]));
    // we want to classify the max value of the 2 results in showing the user the overall summary
    const vPTAMax = Math.max(vPTALeft, vPTARight);
    dispatch({ type: 'UPDATE_VPTAOVERALL', payload: vPTAMax });
    dispatch({ type: 'UPDATE_VPTALEFT', payload: vPTALeft });
    dispatch({ type: 'UPDATE_VPTARIGHT', payload: vPTARight });

    // Values for 500 Hz for both ears for Low
    const vPTALowLeft = hrData.first_test_left_500__c;
    const vPTALowRight = hrData.first_test_right_500__c;

    // Averages for 1000 and 2000 Hz for both ears for Mid
    const vPTAMidLeft = Math.round(calculateAverage([hrData.first_test_left_1000__c, hrData.first_test_left_2000__c]));
    const vPTAMidRight = Math.round(calculateAverage([hrData.first_test_right_1000__c, hrData.first_test_right_2000__c]));

    // Averages of the 2 highest frequencies among 3000, 4000, and 8000 Hz for both ears for High
    const vPTAHighLeft = Math.round(calculateAverage(leftHighFrequencies.sort((a, b) => b - a).slice(0, 2)));
    const vPTAHighRight = Math.round(calculateAverage(rightHighFrequencies.sort((a, b) => b - a).slice(0, 2)));

    const resultsDict = {
      overall: vPTAMax,
      L: {
        overall: vPTALeft,
        low: vPTALowLeft,
        mid: vPTAMidLeft,
        high: vPTAHighLeft,
      },
      R: {
        overall: vPTARight,
        low: vPTALowRight,
        mid: vPTAMidRight,
        high: vPTAHighRight
      }
    }

    resultsUrl += 'results=' + encodeURIComponent(JSON.stringify(resultsDict));
    return resultsUrl;
  }
  
  async function hmacEncode(jsonStr, secret) {
    
    // Convert the JSON string and secret to Uint8Array using TextEncoder
    const encoder = new TextEncoder();
    const data = encoder.encode(jsonStr);
    const keyData = encoder.encode(secret);

    // Import the secret key for use with HMAC
    const key = await crypto.subtle.importKey(
      'raw',
      keyData,
      { name: 'HMAC', hash: { name: 'SHA-256' } },
      false,
      ['sign']
    );

    // Sign the data with HMAC-SHA-256
    const signature = await crypto.subtle.sign(
      'HMAC',
      key,
      data
    );

    // Convert the signature (ArrayBuffer) to a hexadecimal string
    return Array.from(new Uint8Array(signature))
      .map(b => b.toString(16).padStart(2, '0'))
      .join('');
  }

  const postTestData = async (testData, threshold) => {
    const contactData = { id: userInfo.contactid };
    const patientData = { id: userInfo.patientid };

    let earStr = '';
    let freqStr = '';
    let trialData = [];

    if (testData.length) {
      earStr = testData[0].frequency[0] === 'R' ? 'right' : 'left';
      freqStr = testData[0].frequency.slice(1);

      trialData = testData.map(trial => ({
        responseTime: trial.responseTime,
        interval: trial.interval,
        loudness: trial.loudness
      }));

    } else {
      // for skipping test when loudness is 90
      earStr = freqList[0][0] === 'R' ? 'right' : 'left';
      freqStr = freqList[0].slice(1);

      trialData = [{
        responseTime: [],
        interval: [],
        loudness: []
      }];
    }

    const hearingResultData = {
      ...userInfo.hearingresultdata,
      id: userInfo.hearingresultid,
      name_on_result__c: userInfo.nameonresult,
      patient_dob__c: userInfo.dob,
      trial_information__c: {
        ...userInfo.hearingresultdata.trial_information__c,
        [`${earStr}_${freqStr}_trialArray`] : trialData
      },
      [`first_test_${earStr}_${freqStr}__c`]: threshold, 
      [`second_test_${earStr}_${freqStr}__c`]: threshold,
      hearing_result_type__c: 'JHT Web App',
      version_id__c: process.env.REACT_APP_GIT_COMMIT || 'dev',
    }

    dispatch({ type: 'UPDATE_HEARINGRESULTDATA', payload: hearingResultData });

    let activitytypeid = ''
    // JHT Web Halfway Complete versus JHT Web Update Hearing Result
    activitytypeid = freqList[0] === 'R8000' ? 'a02Ud000001UmhlIAC' : 'a028Y00001FFCHLQA5';

    // initialize the activityData
    const data = { 
      contact: contactData,
      patient__c: patientData,
      hearing_result__c: hearingResultData,
      activitytypeid: activitytypeid
    };

    const activityData = JSON.stringify(data);
    const signature = await hmacEncode(activityData, constants['hmacSecret']);
    // console.log('signature:', signature);
    // console.log('activityData:', activityData);

    try {
      const response = await fetch(constants['apiBaseUrl'], {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-AUD-Signature': signature,
        },
        body: activityData,
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      console.error('activityData success!', activityData);
      const responseData = await response.json();
      console.error('responseData', responseData);
      if (!userInfo.hearingresultid) {
        const hearingResultId = responseData.hearing_result__c.id;
        dispatch({ type: 'UPDATE_HEARINGRESULTID', payload: hearingResultId });
      }
    } catch (error) {
      console.error('Error posting data:', error);
      console.error('activityData', activityData);
    }
  };

  // checks various conditions to see if test sequence should stop
  // if so, returns the threshold determination (integer), otherwise returns null
  const checkStoppingCriteria = (testData) => {
    // DEV CODE
    // SPEEDS THROUGH TEST SEQUENCE IN DEV MODE
    if (process.env.REACT_APP_USE_SKIP_TESTS === 'true') {
      if (testData[0].frequency[0] === 'R') {
        postTestData(testData, 20);
        return 20
      } else {
        postTestData(testData, 50);
        return 50
      }
    }
    
    // DETERMINE CURRENT VALUES OF CRITERIA
    // returns true if the max number of trials has been reached
    const maxTrialCheck = testData.length === maxTrialIndex + 1

    // returns the total number of hits at 0, 5, or 10 dB
    const hitsBelow15 = testData.filter(trial => trial.loudness < 15)
      .map(trial => trial.didUserRespond)
      .reduce((count, value) => count + (value ? 1 : 0), 0);

    // returns the total number of misses at 85 dB
    const missesAbove80 = testData.filter(trial => trial.loudness > 80)
      .map(trial => trial.didUserRespond)
      .reduce((count, value) => count + (value ? 0 : 1), 0);

    // get total number of presentations at current loudness +/- 5 dB around current loudness
    const numTrialsCurrent = numTrialsAtLoudness(testData, loudness)
    const numTrialsLower = numTrialsAtLoudness(testData, loudness - 5)
    const numTrialsUpper = numTrialsAtLoudness(testData, loudness + 5)

    // get hit rates at current loudness and +/-5 dB around current loudness
    const hitRateCurrent = hitProportionAtLoudness(testData, loudness)
    const hitRateLower = hitProportionAtLoudness(testData, loudness - 5)
    const hitRateUpper = hitProportionAtLoudness(testData, loudness + 5)

    // DEBUG LOGGING
    // const currTestData = testData[testData.length-1];
    // console.log('trialIndex', currTestData.trialIndex, 'frequency', currTestData.frequency,
    //   'loudness', currTestData.loudness, 'didUserRespond', currTestData.didUserRespond,
    //   'responseTime', currTestData.responseTime, 'hitRateCurrent', hitRateCurrent,
    //   'hitRateLower', hitRateLower, 'hitRateUpper', hitRateUpper, 'numTrialsCurrent', numTrialsCurrent,
    //   'numTrialsLower', numTrialsLower, 'numTrialsUpper', hitRateUpper)

    // NOW EVALUATE CRITERIA AND RETURN THRESHOLD
    if (hitsBelow15 > 2) {
      //console.log('hits below 15 fulfilled, threshold is', 10)
      postTestData(testData, 10);
      return 10
    }
    if (missesAbove80 > 2) {
      //console.log('misses above 80 fulfilled threshold is', 90)
      postTestData(testData, 90);
      return 90
    }

    if (maxTrialCheck) {
      // get array of loudness values with hit
      const loudnessValuesWithHit = testData.filter(
        trial => trial.didUserRespond === true)
        .map(trial => parseInt(trial.loudness, 10));

      // get number of hits at each loudness
      const loudnessHits = loudnessValuesWithHit.map(
        x => numHitsAtLoudness(testData, x));

      // max trials reached so algorithm should make its best guess what the true threshold is
      // the more hits a loudness value has, the more times there was a miss 5 dB below it
      // therefore, the loudness value with most hits is a good candidate for threshold
      // if there is a tie, take the smallest value to err on the side of less hearing loss 
      // (avoids over-amplification in hearing aid) 
      let smallestLoudnessWithMostHits;
      let maxHits = 0;

      for (let i = 0; i < loudnessHits.length; i++) {
        const hits = loudnessHits[i];

        if (
          hits > maxHits 
          || (hits === maxHits 
            && loudnessValuesWithHit[i] < smallestLoudnessWithMostHits)
        ) {
          smallestLoudnessWithMostHits = loudnessValuesWithHit[i];
          maxHits = hits;
        }
      }
      postTestData(testData, smallestLoudnessWithMostHits);
      return smallestLoudnessWithMostHits
    } 

    // ONLY EVALUATE HIT RATES IF AT LEAST 1 MISS HAS BEEN RECORDED AT 5 dB BELOW CURRENT VALUE
    if (numTrialsLower > 0) {
      // returns true if they are 100% with 2 or more trials so far at current loudness OR there have been more than 2 presentations with hit rate > 0.5
      const currentLoudnessCheck = (
        numTrialsCurrent >= 2 && hitRateCurrent === 1) 
        || (numTrialsCurrent > 2 && hitRateCurrent > 0.5);

      // returns true if they are 0/2 so far at 5 dB below current loudness OR > 2 presentations with hit rate < 0.5
      const lowerLoudnessCheck = (
        numTrialsLower >= 2 && hitRateLower === 0) 
        || (numTrialsLower > 2 && hitRateCurrent < 0.5);

      // if both conditions are met, their threshold is the current loudness value
      if (currentLoudnessCheck && lowerLoudnessCheck) {
        postTestData(testData,  parseInt(loudness, 10));
        return parseInt(loudness, 10);
      }
    }
    
    // ALSO CHECK UPPER
    if (numTrialsUpper > 0) {
      // returns true if they are 0/2 so far at current loudness OR there have been more than 2 presentations with hit rate < 0.5
      const currentLoudnessCheck = (
        numTrialsCurrent >= 2 && hitRateCurrent === 0) 
        || (numTrialsCurrent > 2 && hitRateCurrent < 0.5);

      // returns true if they are 2/2 so far at 5 dB above current loudness OR > 2 presentations with hit rate > 0.5
      const upperLoudnessCheck = (
        numTrialsUpper >= 2 && hitRateUpper === 1) 
        || (numTrialsUpper > 2 && hitRateUpper > 0.5);
        
      // if both conditions are met, their threshold is the current loudness value
      if (currentLoudnessCheck && upperLoudnessCheck) {
        //console.log('current and upper checks fulfilled, threshold is', parseInt(loudness) + 5)
        postTestData(testData, parseInt(loudness) + 5);
        // their threhsold is the value 5 dB above current loudness
        return parseInt(loudness) + 5
      }
    }
  };

  const handleEndTest = () => {
    // sequence ended so threshold was posted
    setDataPosted(true);
    // reset testData to [] for next sequence
    resetTestData();
    // remove the frequency that was just tested from the frequency list
    const updatedFreqList = freqList.filter(freq => freq !== frequency);
    if (isTraining) {
      setIsTraining(false);
    } else {
      setFreqList(updatedFreqList);
    }
    if (updatedFreqList.length === 0) {
      // if all frequencies have been tested, end the test and navigate to results
      dispatch({ type: 'UPDATE_RESULTSURL', payload: getResultsUrl() });
      dispatch({ type: 'UPDATE_OVERALLSCORE', payload: getOverallScore() });
      navigate('/results');
      nextStep();
    } else {
      // otherwise set the next frequency
      setFrequency(freqList[0]);
    }
  }

  return { 
    testData, 
    updateTestData,
    resetTestData,
    loudness,
    setLoudness,
    frequency,
    setFrequency,
    isTraining,
    setIsTraining,
    checkStoppingCriteria,
    handleEndTest,
    dataPosted,
    setDataPosted,
    freqList,
  };
}

export default useTest;