import { useLocation } from 'react-router-dom';

const steps = ['Setup', 'Training', 'Right Ear', 'Left Ear', 'Results'];

const ProgressBar = ({ currentStep, progress }) => {
  const { pathname } = useLocation();

  if (pathname !== '/' && pathname !== '/results/detailedresults') {
    return (
      <div className="progress-bar">
        {steps.map((stepName, i) => (
          <div
            className="progress-bar-step"
            key={`progress-step-${i}`}
          >
            <p>{stepName}</p>
              <div 
              className="progress-bar-background-color"
              style={{ 
                width: `${currentStep > i ? '100' : currentStep < i ? '0' : progress}%`,
                transition: 'width 0.8s ease-in-out'
              }}
              />
          </div>
        ))}
      </div>
    );
  } else {
    return <></>;
  }
}

export default ProgressBar;