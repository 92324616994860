import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom'
import Modal from '../components/Modal';
import { useUserInfo } from '../context/UserInfoContext';
import { useNavigate } from 'react-router-dom';

import closed from '../assets/images/closed-default.png';
import earbuds from '../assets/images/earbuds-default.png';
import headphones from '../assets/images/headphones-default.png';
import bluetooth from '../assets/images/bluetooth.png';
import noiseCancel from '../assets/images/noisecancel.png';
import auxPort from '../assets/images/aux-port.png';
import volume from '../assets/images/half-volume-yellow.png';
import bellSound from "../assets/audio/wav/bell-sound.wav";

/* global heap */

const Device = ({ setProgress }) => {
  const [selected, setSelected] = useState(false);
  // open-ear headphones have different modal content
  const [openSelected, setOpenSelected] = useState(false);
  const [testSoundPlayed, setTestSoundPlayed] = useState(false);

  const audioRef = useRef(null);

  const { userInfo } = useUserInfo();
  const navigate = useNavigate();

  useEffect(() => {
    if (!userInfo.contactid) {
      return navigate('/demographics');
    } else {
      setProgress();
      // we stopped paying for heap as of July 2024
      // const gaClientId = window.gaconnector2.debugData().gaClientId;
      // heap.identify(gaClientId);
      // heap.track('JHT_SUBMIT_DEMOGRAPHICS');
      // console.log('gaClientId:', gaClientId);
    }
  }, [setProgress, navigate, userInfo]);

  const handlePlayAudio = () => {
    setTestSoundPlayed(true);
    if (audioRef && audioRef.current) {
      audioRef.current.play();
    }
  }

  return (
    <div className="device">
      <h1 className="top-title-text">
        What Kind of Listening Device Are You Using?
      </h1>
      <div className="main-content">
        <button 
          className="device-button"
          onClick={() => setSelected(true)}
        >
          <img src={closed} alt="closed earbuds" />
          <p>Closed Earbuds (in-ear)</p>
        </button>
        <button 
          className="device-button"
          onClick={() => {
            setSelected(true);
            setOpenSelected(true);
          }}
        >
          <img src={earbuds} alt="open earbuds" />
          <p>Wireless Earbuds (e.g. AirPods)</p>
        </button>
        <button 
          className="device-button"
          onClick={() => setSelected(true)}
        >
          <img src={headphones} alt="headphones" />
          <p>Headphones (over-ear)</p>
        </button>
      </div>

      <audio ref={audioRef}>
        <source src={bellSound} />
      </audio>

      {selected && (
        <Modal 
          closeModal={() => {
            setSelected(false);
            setOpenSelected(false);
          }}
        >
          <div className="volume-container">
            <img src={volume} alt="half volume" />
          </div>
          <p className="device-modal-text">Set your volume to 50%</p>

          {openSelected ? (
            <>
              <img 
                src={bluetooth} 
                alt="bluetooth" 
                style={{ marginTop: '15px' }}
              />
              <p className="device-modal-text">Connect to bluetooth</p>
              <img 
                src={noiseCancel} 
                alt="noise canceling" 
                style={{ marginTop: '10px' }}
              />
              <p className="device-modal-text">Turn off noise-canceling</p>
            </>
          ) : (
            <>
              <img 
                src={auxPort} 
                alt="aux port" 
                style={{ margin: '25px 0 10px 0' }}
              />
              <p className="device-modal-text">Plug into your aux port</p>
            </>
          )}
            <button
              onClick={() => handlePlayAudio()}
              className="link-button"
              style={{ background: 'var(--orange', marginBottom: '10px' }}
            >
              <p style={{ color: '#FFF', margin: 0 }}>Test Sound</p>
            </button>
            <p style={{ fontFamily: 'Georgia', color: '#6F6F6F', width: '270px', textAlign: 'center', marginTop: '5px' }}>
              Make sure you can hear the test sound before continuing
            </p>
          

          <Link 
            className="link-button"
            style={{ 
              background: 'none', 
              color: 'var(--dark-blue)',
              boxShadow: 'none',
              margin: 0,
              marginTop: '-13px',
              pointerEvents: testSoundPlayed ? 'auto' : 'none',
              opacity: testSoundPlayed ? 1 : 0.5,
            }}
            to="/environment"
            onMouseEnter={(e) => {
              if (testSoundPlayed) {
                e.currentTarget.style.opacity = 0.6;
              }
            }}
            onMouseLeave={(e) => {
              if (testSoundPlayed) {
                e.currentTarget.style.opacity = 1;
              }
            }}
          >
            Continue
          </Link>
        </Modal>
      )}
    </div>
  );
}

export default Device;