import React, { useEffect, useRef, useState } from 'react';
import audioData from '../assets/audio/index';

const isMobile = window.innerWidth <= 620;

const AudioPlayer = ({ 
  loudness, 
  frequency, 
  autoPlay, 
  isTestScreen,
  disableButtons
}) => {
  const audioRef = useRef(null);
  const [firstAudio, setFirstAudio] = useState(true);

  useEffect(() => {
    if (!autoPlay && isTestScreen) return;
    // have to reload audio when loudness changes the audio src
    if (audioRef && audioRef.current) {
      // ignore for first audio file to avoid promise error
      if (firstAudio) {
        setFirstAudio(false);
        return;
      }

      if (disableButtons && isMobile) disableButtons(true);

      audioRef.current.pause();
      audioRef.current.load();
      // audioRef.current.play();

      var playPromise = audioRef.current.play();

      // avoid audio error
      if (playPromise !== undefined) {
        playPromise.then(_ => {})
        .catch(error => {});
      }

      if (disableButtons && isMobile) {
        setTimeout(() => disableButtons(false), 500);
      }
      
    }
  }, [loudness, firstAudio, autoPlay, isTestScreen, disableButtons]);

  return (
    <audio
      ref={audioRef}
      id="audio" 
      preload="true"
      autoPlay={autoPlay}
    >
      <source src={audioData[`${frequency}_${loudness}dB.wav`]} />
    </audio>
  )
};

export default AudioPlayer;
