import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

// import volume from '../assets/images/half-volume.png';
import checked from '../assets/images/checkbox-selected.png';
import unchecked from '../assets/images/checkbox-unselected.png';
import { useUserInfo } from '../context/UserInfoContext';

const Environment = ({ trackEvent, setProgress }) => {
  const [checkedArray, setCheckedArray] = useState([false, false, false, false, false]);
  const [tracked, setTracked] = useState(false);
  const { userInfo } = useUserInfo();
  const navigate = useNavigate();

  useEffect(() => {
    if (!userInfo.contactid) {
      return navigate('/demographics');
    }
    if (!tracked) trackEvent('JHT_ENVIRONMENT_PAGE_LOAD', userInfo.email);
    setTracked(true);
    setProgress();
  }, [tracked, setTracked, setProgress, trackEvent, userInfo, navigate]);

  const handleButtonClick = index => {
    const updatedArray = [...checkedArray];
    updatedArray.splice(index, 1, true);
    setCheckedArray(updatedArray);
  }

  return (
    <div className="environment">
      <h1 className="top-title-text">
        Let's Get You in the Right Listening Environment
      </h1>
      <p>
        Please be sure to check every box in order to receive
        <strong> valid test results </strong>
      </p>
      <div className="main-content">
      <button 
          className="environment-button"
          onClick={() => handleButtonClick(0)}
        >
          <img 
            src={checkedArray[0] ? checked : unchecked} 
            alt="checkbox"
          />
          <p>
            I'm at least <strong>18 years old</strong>
          </p>
        </button>
        <button 
          className="environment-button"
          onClick={() => handleButtonClick(1)}
        >
          <img 
            src={checkedArray[1] ? checked : unchecked} 
            alt="checkbox"
          />
          <p>I'm in a <strong>quiet room</strong></p>
        </button>
        <button 
          className="environment-button"
          onClick={() => handleButtonClick(2)}
        >
          <img 
            src={checkedArray[2] ? checked : unchecked} 
            alt="checkbox"
          />
          <p>My volume is set to 50% and I will keep it at <strong>50% during the test</strong></p>
        </button>
        <button 
          className="environment-button"
          onClick={() => handleButtonClick(3)}
        >
          <img 
            src={checkedArray[3] ? checked : unchecked} 
            alt="checkbox"
          />
          <p>
            I <strong>turned off noise-canceling</strong> on my headphones (if applicable)
          </p>
        </button>
        <button 
          className="environment-button"
          onClick={() => handleButtonClick(4)}
        >
          <img 
            src={checkedArray[4] ? checked : unchecked} 
            alt="checkbox"
          />
          <p>
            I <strong>removed my hearing aids</strong> for the test (if applicable)
            
          </p>
        </button>
        {/* <img src={volume} alt="half volume" /> */}
      </div>
      <Link 
        className={`
          link-button 
          ${checkedArray.includes(false) ? 'link-button-disabled' : ''}
        `}
        to="/headphones"
      >
        Continue
      </Link>
    </div>
  );
}

export default Environment;