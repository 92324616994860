module.exports = {
  
  classifyHearing: (value) => {
    if (value <= 25) return "no";
    if (value <= 40) return "some";
    if (value <= 70) return "significant";
    return "severe";
  },

  colorCodes: {
    severe: '#D55F19',
    significant: '#FA9052',
    some: '#FFBB93',
    no: '#3EB1D3',
  }
}