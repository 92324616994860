import testSoundRight from "./wav/bell-sound-right.wav";
import bellSound from "./wav/bell-sound.wav";

import R500_0 from "./wav/R500_0dB.wav";
import R500_5 from "./wav/R500_5dB.wav";
import R500_10 from "./wav/R500_10dB.wav";
import R500_15 from "./wav/R500_15dB.wav";
import R500_20 from "./wav/R500_20dB.wav";
import R500_25 from "./wav/R500_25dB.wav";
import R500_30 from "./wav/R500_30dB.wav";
import R500_35 from "./wav/R500_35dB.wav";
import R500_40 from "./wav/R500_40dB.wav";
import R500_45 from "./wav/R500_45dB.wav";
import R500_50 from "./wav/R500_50dB.wav";
import R500_55 from "./wav/R500_55dB.wav";
import R500_60 from "./wav/R500_60dB.wav";
import R500_65 from "./wav/R500_65dB.wav";
import R500_70 from "./wav/R500_70dB.wav";
import R500_75 from "./wav/R500_75dB.wav";
import R500_80 from "./wav/R500_80dB.wav";
import R500_85 from "./wav/R500_85dB.wav";
import R500_90 from "./wav/R500_90dB.wav";

import L500_0 from "./wav/L500_0dB.wav";
import L500_5 from "./wav/L500_5dB.wav";
import L500_10 from "./wav/L500_10dB.wav";
import L500_15 from "./wav/L500_15dB.wav";
import L500_20 from "./wav/L500_20dB.wav";
import L500_25 from "./wav/L500_25dB.wav";
import L500_30 from "./wav/L500_30dB.wav";
import L500_35 from "./wav/L500_35dB.wav";
import L500_40 from "./wav/L500_40dB.wav";
import L500_45 from "./wav/L500_45dB.wav";
import L500_50 from "./wav/L500_50dB.wav";
import L500_55 from "./wav/L500_55dB.wav";
import L500_60 from "./wav/L500_60dB.wav";
import L500_65 from "./wav/L500_65dB.wav";
import L500_70 from "./wav/L500_70dB.wav";
import L500_75 from "./wav/L500_75dB.wav";
import L500_80 from "./wav/L500_80dB.wav";
import L500_85 from "./wav/L500_85dB.wav";
import L500_90 from "./wav/L500_90dB.wav";

import R1000_0 from "./wav/R1000_0dB.wav";
import R1000_5 from "./wav/R1000_5dB.wav";
import R1000_10 from "./wav/R1000_10dB.wav";
import R1000_15 from "./wav/R1000_15dB.wav";
import R1000_20 from "./wav/R1000_20dB.wav";
import R1000_25 from "./wav/R1000_25dB.wav";
import R1000_30 from "./wav/R1000_30dB.wav";
import R1000_35 from "./wav/R1000_35dB.wav";
import R1000_40 from "./wav/R1000_40dB.wav";
import R1000_45 from "./wav/R1000_45dB.wav";
import R1000_50 from "./wav/R1000_50dB.wav";
import R1000_55 from "./wav/R1000_55dB.wav";
import R1000_60 from "./wav/R1000_60dB.wav";
import R1000_65 from "./wav/R1000_65dB.wav";
import R1000_70 from "./wav/R1000_70dB.wav";
import R1000_75 from "./wav/R1000_75dB.wav";
import R1000_80 from "./wav/R1000_80dB.wav";
import R1000_85 from "./wav/R1000_85dB.wav";
import R1000_90 from "./wav/R1000_90dB.wav";

import L1000_0 from "./wav/L1000_0dB.wav";
import L1000_5 from "./wav/L1000_5dB.wav";
import L1000_10 from "./wav/L1000_10dB.wav";
import L1000_15 from "./wav/L1000_15dB.wav";
import L1000_20 from "./wav/L1000_20dB.wav";
import L1000_25 from "./wav/L1000_25dB.wav";
import L1000_30 from "./wav/L1000_30dB.wav";
import L1000_35 from "./wav/L1000_35dB.wav";
import L1000_40 from "./wav/L1000_40dB.wav";
import L1000_45 from "./wav/L1000_45dB.wav";
import L1000_50 from "./wav/L1000_50dB.wav";
import L1000_55 from "./wav/L1000_55dB.wav";
import L1000_60 from "./wav/L1000_60dB.wav";
import L1000_65 from "./wav/L1000_65dB.wav";
import L1000_70 from "./wav/L1000_70dB.wav";
import L1000_75 from "./wav/L1000_75dB.wav";
import L1000_80 from "./wav/L1000_80dB.wav";
import L1000_85 from "./wav/L1000_85dB.wav";
import L1000_90 from "./wav/L1000_90dB.wav";

import R2000_0 from "./wav/R2000_0dB.wav";
import R2000_5 from "./wav/R2000_5dB.wav";
import R2000_10 from "./wav/R2000_10dB.wav";
import R2000_15 from "./wav/R2000_15dB.wav";
import R2000_20 from "./wav/R2000_20dB.wav";
import R2000_25 from "./wav/R2000_25dB.wav";
import R2000_30 from "./wav/R2000_30dB.wav";
import R2000_35 from "./wav/R2000_35dB.wav";
import R2000_40 from "./wav/R2000_40dB.wav";
import R2000_45 from "./wav/R2000_45dB.wav";
import R2000_50 from "./wav/R2000_50dB.wav";
import R2000_55 from "./wav/R2000_55dB.wav";
import R2000_60 from "./wav/R2000_60dB.wav";
import R2000_65 from "./wav/R2000_65dB.wav";
import R2000_70 from "./wav/R2000_70dB.wav";
import R2000_75 from "./wav/R2000_75dB.wav";
import R2000_80 from "./wav/R2000_80dB.wav";
import R2000_85 from "./wav/R2000_85dB.wav";
import R2000_90 from "./wav/R2000_90dB.wav";

import L2000_0 from "./wav/L2000_0dB.wav";
import L2000_5 from "./wav/L2000_5dB.wav";
import L2000_10 from "./wav/L2000_10dB.wav";
import L2000_15 from "./wav/L2000_15dB.wav";
import L2000_20 from "./wav/L2000_20dB.wav";
import L2000_25 from "./wav/L2000_25dB.wav";
import L2000_30 from "./wav/L2000_30dB.wav";
import L2000_35 from "./wav/L2000_35dB.wav";
import L2000_40 from "./wav/L2000_40dB.wav";
import L2000_45 from "./wav/L2000_45dB.wav";
import L2000_50 from "./wav/L2000_50dB.wav";
import L2000_55 from "./wav/L2000_55dB.wav";
import L2000_60 from "./wav/L2000_60dB.wav";
import L2000_65 from "./wav/L2000_65dB.wav";
import L2000_70 from "./wav/L2000_70dB.wav";
import L2000_75 from "./wav/L2000_75dB.wav";
import L2000_80 from "./wav/L2000_80dB.wav";
import L2000_85 from "./wav/L2000_85dB.wav";
import L2000_90 from "./wav/L2000_90dB.wav";

import R3000_0 from "./wav/R3000_0dB.wav";
import R3000_5 from "./wav/R3000_5dB.wav";
import R3000_10 from "./wav/R3000_10dB.wav";
import R3000_15 from "./wav/R3000_15dB.wav";
import R3000_20 from "./wav/R3000_20dB.wav";
import R3000_25 from "./wav/R3000_25dB.wav";
import R3000_30 from "./wav/R3000_30dB.wav";
import R3000_35 from "./wav/R3000_35dB.wav";
import R3000_40 from "./wav/R3000_40dB.wav";
import R3000_45 from "./wav/R3000_45dB.wav";
import R3000_50 from "./wav/R3000_50dB.wav";
import R3000_55 from "./wav/R3000_55dB.wav";
import R3000_60 from "./wav/R3000_60dB.wav";
import R3000_65 from "./wav/R3000_65dB.wav";
import R3000_70 from "./wav/R3000_70dB.wav";
import R3000_75 from "./wav/R3000_75dB.wav";
import R3000_80 from "./wav/R3000_80dB.wav";
import R3000_85 from "./wav/R3000_85dB.wav";
import R3000_90 from "./wav/R3000_90dB.wav";

import L3000_0 from "./wav/L3000_0dB.wav";
import L3000_5 from "./wav/L3000_5dB.wav";
import L3000_10 from "./wav/L3000_10dB.wav";
import L3000_15 from "./wav/L3000_15dB.wav";
import L3000_20 from "./wav/L3000_20dB.wav";
import L3000_25 from "./wav/L3000_25dB.wav";
import L3000_30 from "./wav/L3000_30dB.wav";
import L3000_35 from "./wav/L3000_35dB.wav";
import L3000_40 from "./wav/L3000_40dB.wav";
import L3000_45 from "./wav/L3000_45dB.wav";
import L3000_50 from "./wav/L3000_50dB.wav";
import L3000_55 from "./wav/L3000_55dB.wav";
import L3000_60 from "./wav/L3000_60dB.wav";
import L3000_65 from "./wav/L3000_65dB.wav";
import L3000_70 from "./wav/L3000_70dB.wav";
import L3000_75 from "./wav/L3000_75dB.wav";
import L3000_80 from "./wav/L3000_80dB.wav";
import L3000_85 from "./wav/L3000_85dB.wav";
import L3000_90 from "./wav/L3000_90dB.wav";

import R4000_0 from "./wav/R4000_0dB.wav";
import R4000_5 from "./wav/R4000_5dB.wav";
import R4000_10 from "./wav/R4000_10dB.wav";
import R4000_15 from "./wav/R4000_15dB.wav";
import R4000_20 from "./wav/R4000_20dB.wav";
import R4000_25 from "./wav/R4000_25dB.wav";
import R4000_30 from "./wav/R4000_30dB.wav";
import R4000_35 from "./wav/R4000_35dB.wav";
import R4000_40 from "./wav/R4000_40dB.wav";
import R4000_45 from "./wav/R4000_45dB.wav";
import R4000_50 from "./wav/R4000_50dB.wav";
import R4000_55 from "./wav/R4000_55dB.wav";
import R4000_60 from "./wav/R4000_60dB.wav";
import R4000_65 from "./wav/R4000_65dB.wav";
import R4000_70 from "./wav/R4000_70dB.wav";
import R4000_75 from "./wav/R4000_75dB.wav";
import R4000_80 from "./wav/R4000_80dB.wav";
import R4000_85 from "./wav/R4000_85dB.wav";
import R4000_90 from "./wav/R4000_90dB.wav";

import L4000_0 from "./wav/L4000_0dB.wav";
import L4000_5 from "./wav/L4000_5dB.wav";
import L4000_10 from "./wav/L4000_10dB.wav";
import L4000_15 from "./wav/L4000_15dB.wav";
import L4000_20 from "./wav/L4000_20dB.wav";
import L4000_25 from "./wav/L4000_25dB.wav";
import L4000_30 from "./wav/L4000_30dB.wav";
import L4000_35 from "./wav/L4000_35dB.wav";
import L4000_40 from "./wav/L4000_40dB.wav";
import L4000_45 from "./wav/L4000_45dB.wav";
import L4000_50 from "./wav/L4000_50dB.wav";
import L4000_55 from "./wav/L4000_55dB.wav";
import L4000_60 from "./wav/L4000_60dB.wav";
import L4000_65 from "./wav/L4000_65dB.wav";
import L4000_70 from "./wav/L4000_70dB.wav";
import L4000_75 from "./wav/L4000_75dB.wav";
import L4000_80 from "./wav/L4000_80dB.wav";
import L4000_85 from "./wav/L4000_85dB.wav";
import L4000_90 from "./wav/L4000_90dB.wav";

import R8000_0 from "./wav/R8000_0dB.wav";
import R8000_5 from "./wav/R8000_5dB.wav";
import R8000_10 from "./wav/R8000_10dB.wav";
import R8000_15 from "./wav/R8000_15dB.wav";
import R8000_20 from "./wav/R8000_20dB.wav";
import R8000_25 from "./wav/R8000_25dB.wav";
import R8000_30 from "./wav/R8000_30dB.wav";
import R8000_35 from "./wav/R8000_35dB.wav";
import R8000_40 from "./wav/R8000_40dB.wav";
import R8000_45 from "./wav/R8000_45dB.wav";
import R8000_50 from "./wav/R8000_50dB.wav";
import R8000_55 from "./wav/R8000_55dB.wav";
import R8000_60 from "./wav/R8000_60dB.wav";
import R8000_65 from "./wav/R8000_65dB.wav";
import R8000_70 from "./wav/R8000_70dB.wav";
import R8000_75 from "./wav/R8000_75dB.wav";
import R8000_80 from "./wav/R8000_80dB.wav";
import R8000_85 from "./wav/R8000_85dB.wav";
import R8000_90 from "./wav/R8000_90dB.wav";

import L8000_0 from "./wav/L8000_0dB.wav";
import L8000_5 from "./wav/L8000_5dB.wav";
import L8000_10 from "./wav/L8000_10dB.wav";
import L8000_15 from "./wav/L8000_15dB.wav";
import L8000_20 from "./wav/L8000_20dB.wav";
import L8000_25 from "./wav/L8000_25dB.wav";
import L8000_30 from "./wav/L8000_30dB.wav";
import L8000_35 from "./wav/L8000_35dB.wav";
import L8000_40 from "./wav/L8000_40dB.wav";
import L8000_45 from "./wav/L8000_45dB.wav";
import L8000_50 from "./wav/L8000_50dB.wav";
import L8000_55 from "./wav/L8000_55dB.wav";
import L8000_60 from "./wav/L8000_60dB.wav";
import L8000_65 from "./wav/L8000_65dB.wav";
import L8000_70 from "./wav/L8000_70dB.wav";
import L8000_75 from "./wav/L8000_75dB.wav";
import L8000_80 from "./wav/L8000_80dB.wav";
import L8000_85 from "./wav/L8000_85dB.wav";
import L8000_90 from "./wav/L8000_90dB.wav";

const audioData = {

  'testSoundRight': testSoundRight,

  'bellSound': bellSound,
    
  'R500_0dB.wav': R500_0,
  'R500_5dB.wav': R500_5,
  'R500_10dB.wav': R500_10,
  'R500_15dB.wav': R500_15,
  'R500_20dB.wav': R500_20,
  'R500_25dB.wav': R500_25,
  'R500_30dB.wav': R500_30,
  'R500_35dB.wav': R500_35,
  'R500_40dB.wav': R500_40,
  'R500_45dB.wav': R500_45,
  'R500_50dB.wav': R500_50,
  'R500_55dB.wav': R500_55,
  'R500_60dB.wav': R500_60,
  'R500_65dB.wav': R500_65,
  'R500_70dB.wav': R500_70,
  'R500_75dB.wav': R500_75,
  'R500_80dB.wav': R500_80,
  'R500_85dB.wav': R500_85,
  'R500_90dB.wav': R500_90,

  'L500_0dB.wav': L500_0,
  'L500_5dB.wav': L500_5,
  'L500_10dB.wav': L500_10,
  'L500_15dB.wav': L500_15,
  'L500_20dB.wav': L500_20,
  'L500_25dB.wav': L500_25,
  'L500_30dB.wav': L500_30,
  'L500_35dB.wav': L500_35,
  'L500_40dB.wav': L500_40,
  'L500_45dB.wav': L500_45,
  'L500_50dB.wav': L500_50,
  'L500_55dB.wav': L500_55,
  'L500_60dB.wav': L500_60,
  'L500_65dB.wav': L500_65,
  'L500_70dB.wav': L500_70,
  'L500_75dB.wav': L500_75,
  'L500_80dB.wav': L500_80,
  'L500_85dB.wav': L500_85,
  'L500_90dB.wav': L500_90,

  'R1000_0dB.wav': R1000_0,
  'R1000_5dB.wav': R1000_5,
  'R1000_10dB.wav': R1000_10,
  'R1000_15dB.wav': R1000_15,
  'R1000_20dB.wav': R1000_20,
  'R1000_25dB.wav': R1000_25,
  'R1000_30dB.wav': R1000_30,
  'R1000_35dB.wav': R1000_35,
  'R1000_40dB.wav': R1000_40,
  'R1000_45dB.wav': R1000_45,
  'R1000_50dB.wav': R1000_50,
  'R1000_55dB.wav': R1000_55,
  'R1000_60dB.wav': R1000_60,
  'R1000_65dB.wav': R1000_65,
  'R1000_70dB.wav': R1000_70,
  'R1000_75dB.wav': R1000_75,
  'R1000_80dB.wav': R1000_80,
  'R1000_85dB.wav': R1000_85,
  'R1000_90dB.wav': R1000_90,

  'L1000_0dB.wav': L1000_0,
  'L1000_5dB.wav': L1000_5,
  'L1000_10dB.wav': L1000_10,
  'L1000_15dB.wav': L1000_15,
  'L1000_20dB.wav': L1000_20,
  'L1000_25dB.wav': L1000_25,
  'L1000_30dB.wav': L1000_30,
  'L1000_35dB.wav': L1000_35,
  'L1000_40dB.wav': L1000_40,
  'L1000_45dB.wav': L1000_45,
  'L1000_50dB.wav': L1000_50,
  'L1000_55dB.wav': L1000_55,
  'L1000_60dB.wav': L1000_60,
  'L1000_65dB.wav': L1000_65,
  'L1000_70dB.wav': L1000_70,
  'L1000_75dB.wav': L1000_75,
  'L1000_80dB.wav': L1000_80,
  'L1000_85dB.wav': L1000_85,
  'L1000_90dB.wav': L1000_90,

  'R2000_0dB.wav': R2000_0,
  'R2000_5dB.wav': R2000_5,
  'R2000_10dB.wav': R2000_10,
  'R2000_15dB.wav': R2000_15,
  'R2000_20dB.wav': R2000_20,
  'R2000_25dB.wav': R2000_25,
  'R2000_30dB.wav': R2000_30,
  'R2000_35dB.wav': R2000_35,
  'R2000_40dB.wav': R2000_40,
  'R2000_45dB.wav': R2000_45,
  'R2000_50dB.wav': R2000_50,
  'R2000_55dB.wav': R2000_55,
  'R2000_60dB.wav': R2000_60,
  'R2000_65dB.wav': R2000_65,
  'R2000_70dB.wav': R2000_70,
  'R2000_75dB.wav': R2000_75,
  'R2000_80dB.wav': R2000_80,
  'R2000_85dB.wav': R2000_85,
  'R2000_90dB.wav': R2000_90,

  'L2000_0dB.wav': L2000_0,
  'L2000_5dB.wav': L2000_5,
  'L2000_10dB.wav': L2000_10,
  'L2000_15dB.wav': L2000_15,
  'L2000_20dB.wav': L2000_20,
  'L2000_25dB.wav': L2000_25,
  'L2000_30dB.wav': L2000_30,
  'L2000_35dB.wav': L2000_35,
  'L2000_40dB.wav': L2000_40,
  'L2000_45dB.wav': L2000_45,
  'L2000_50dB.wav': L2000_50,
  'L2000_55dB.wav': L2000_55,
  'L2000_60dB.wav': L2000_60,
  'L2000_65dB.wav': L2000_65,
  'L2000_70dB.wav': L2000_70,
  'L2000_75dB.wav': L2000_75,
  'L2000_80dB.wav': L2000_80,
  'L2000_85dB.wav': L2000_85,
  'L2000_90dB.wav': L2000_90,

  'R3000_0dB.wav': R3000_0,
  'R3000_5dB.wav': R3000_5,
  'R3000_10dB.wav': R3000_10,
  'R3000_15dB.wav': R3000_15,
  'R3000_20dB.wav': R3000_20,
  'R3000_25dB.wav': R3000_25,
  'R3000_30dB.wav': R3000_30,
  'R3000_35dB.wav': R3000_35,
  'R3000_40dB.wav': R3000_40,
  'R3000_45dB.wav': R3000_45,
  'R3000_50dB.wav': R3000_50,
  'R3000_55dB.wav': R3000_55,
  'R3000_60dB.wav': R3000_60,
  'R3000_65dB.wav': R3000_65,
  'R3000_70dB.wav': R3000_70,
  'R3000_75dB.wav': R3000_75,
  'R3000_80dB.wav': R3000_80,
  'R3000_85dB.wav': R3000_85,
  'R3000_90dB.wav': R3000_90,

  'L3000_0dB.wav': L3000_0,
  'L3000_5dB.wav': L3000_5,
  'L3000_10dB.wav': L3000_10,
  'L3000_15dB.wav': L3000_15,
  'L3000_20dB.wav': L3000_20,
  'L3000_25dB.wav': L3000_25,
  'L3000_30dB.wav': L3000_30,
  'L3000_35dB.wav': L3000_35,
  'L3000_40dB.wav': L3000_40,
  'L3000_45dB.wav': L3000_45,
  'L3000_50dB.wav': L3000_50,
  'L3000_55dB.wav': L3000_55,
  'L3000_60dB.wav': L3000_60,
  'L3000_65dB.wav': L3000_65,
  'L3000_70dB.wav': L3000_70,
  'L3000_75dB.wav': L3000_75,
  'L3000_80dB.wav': L3000_80,
  'L3000_85dB.wav': L3000_85,
  'L3000_90dB.wav': L3000_90,

  'R4000_0dB.wav': R4000_0,
  'R4000_5dB.wav': R4000_5,
  'R4000_10dB.wav': R4000_10,
  'R4000_15dB.wav': R4000_15,
  'R4000_20dB.wav': R4000_20,
  'R4000_25dB.wav': R4000_25,
  'R4000_30dB.wav': R4000_30,
  'R4000_35dB.wav': R4000_35,
  'R4000_40dB.wav': R4000_40,
  'R4000_45dB.wav': R4000_45,
  'R4000_50dB.wav': R4000_50,
  'R4000_55dB.wav': R4000_55,
  'R4000_60dB.wav': R4000_60,
  'R4000_65dB.wav': R4000_65,
  'R4000_70dB.wav': R4000_70,
  'R4000_75dB.wav': R4000_75,
  'R4000_80dB.wav': R4000_80,
  'R4000_85dB.wav': R4000_85,
  'R4000_90dB.wav': R4000_90,

  'L4000_0dB.wav': L4000_0,
  'L4000_5dB.wav': L4000_5,
  'L4000_10dB.wav': L4000_10,
  'L4000_15dB.wav': L4000_15,
  'L4000_20dB.wav': L4000_20,
  'L4000_25dB.wav': L4000_25,
  'L4000_30dB.wav': L4000_30,
  'L4000_35dB.wav': L4000_35,
  'L4000_40dB.wav': L4000_40,
  'L4000_45dB.wav': L4000_45,
  'L4000_50dB.wav': L4000_50,
  'L4000_55dB.wav': L4000_55,
  'L4000_60dB.wav': L4000_60,
  'L4000_65dB.wav': L4000_65,
  'L4000_70dB.wav': L4000_70,
  'L4000_75dB.wav': L4000_75,
  'L4000_80dB.wav': L4000_80,
  'L4000_85dB.wav': L4000_85,
  'L4000_90dB.wav': L4000_90,

  'R8000_0dB.wav': R8000_0,
  'R8000_5dB.wav': R8000_5,
  'R8000_10dB.wav': R8000_10,
  'R8000_15dB.wav': R8000_15,
  'R8000_20dB.wav': R8000_20,
  'R8000_25dB.wav': R8000_25,
  'R8000_30dB.wav': R8000_30,
  'R8000_35dB.wav': R8000_35,
  'R8000_40dB.wav': R8000_40,
  'R8000_45dB.wav': R8000_45,
  'R8000_50dB.wav': R8000_50,
  'R8000_55dB.wav': R8000_55,
  'R8000_60dB.wav': R8000_60,
  'R8000_65dB.wav': R8000_65,
  'R8000_70dB.wav': R8000_70,
  'R8000_75dB.wav': R8000_75,
  'R8000_80dB.wav': R8000_80,
  'R8000_85dB.wav': R8000_85,
  'R8000_90dB.wav': R8000_90,

  'L8000_0dB.wav': L8000_0,
  'L8000_5dB.wav': L8000_5,
  'L8000_10dB.wav': L8000_10,
  'L8000_15dB.wav': L8000_15,
  'L8000_20dB.wav': L8000_20,
  'L8000_25dB.wav': L8000_25,
  'L8000_30dB.wav': L8000_30,
  'L8000_35dB.wav': L8000_35,
  'L8000_40dB.wav': L8000_40,
  'L8000_45dB.wav': L8000_45,
  'L8000_50dB.wav': L8000_50,
  'L8000_55dB.wav': L8000_55,
  'L8000_60dB.wav': L8000_60,
  'L8000_65dB.wav': L8000_65,
  'L8000_70dB.wav': L8000_70,
  'L8000_75dB.wav': L8000_75,
  'L8000_80dB.wav': L8000_80,
  'L8000_85dB.wav': L8000_85,
  'L8000_90dB.wav': L8000_90,
};

export default audioData;
