import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
import { useUserInfo } from '../context/UserInfoContext';
import constants from '../constants'
import { classifyHearing } from '../utils';

import greyCheck from '../assets/images/grey-check.png';

const Results = ({ trackEvent }) => {
  const { userInfo } = useUserInfo();
  const [loading, setLoading] = useState(false);
  const [tracked, setTracked] = useState(false);

  useEffect(() => {
    if (!tracked) { 
      trackEvent('JHT_RESULTS_PAGE_LOAD', userInfo.email);
      if (userInfo.overallscore === 2) {
        trackEvent('JHT_OVERALLSCORE_2', userInfo.email);
        trackEvent('JHT_OVERALLSCORE_23', userInfo.email);
      } else if (userInfo.overallscore === 3) {
        trackEvent('JHT_OVERALLSCORE_3', userInfo.email);
        trackEvent('JHT_OVERALLSCORE_23', userInfo.email);
      }
      setTracked(true);
    }
  }, [trackEvent, tracked, setTracked, userInfo]);

  async function hmacEncode(jsonStr, secret) {
    
    // Convert the JSON string and secret to Uint8Array using TextEncoder
    const encoder = new TextEncoder();
    const data = encoder.encode(jsonStr);
    const keyData = encoder.encode(secret);

    // Import the secret key for use with HMAC
    const key = await crypto.subtle.importKey(
      'raw',
      keyData,
      { name: 'HMAC', hash: { name: 'SHA-256' } },
      false,
      ['sign']
    );

    // Sign the data with HMAC-SHA-256
    const signature = await crypto.subtle.sign(
      'HMAC',
      key,
      data
    );

    // Convert the signature (ArrayBuffer) to a hexadecimal string
    return Array.from(new Uint8Array(signature))
      .map(b => b.toString(16).padStart(2, '0'))
      .join('');
  }

  const handleViewResults = async () => {
    setLoading(true);
    // send one last payload with results url and overall score
    // need to send it here so that test data is complete
    const hearingResultData = {    
      id: userInfo.hearingresultid,
      results_url__c: userInfo.resultsurl,
      overall_score__c: userInfo.overallscore,
      jht_vpta_left__c: userInfo.vptaleft,
      jht_vpta_right__c: userInfo.vptaright,
    }

    const contactData = {
      id: userInfo.contactid,
      latest_oht_results_url__c: userInfo.resultsurl,
      email_tht_results_klaviyo__c: new Date(),
      
    };
    const patientData = { id: userInfo.patientid };
    // initialize the activityData
    const data = { 
      contact: contactData,
      patient__c: patientData,
      hearing_result__c: hearingResultData,
      activitytypeid: 'a02Ud000001UoEvIAK' // JHT Web Fully Complete
    };
    const activityData = JSON.stringify(data);

    const signature = await hmacEncode(activityData, constants['hmacSecret']);

    try {
      const response = await fetch(constants['apiBaseUrl'], { 
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-AUD-Signature': signature
        },
        body: activityData,
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      
    } catch (error) {
      console.error('Error posting data:', error);
    }

    setTimeout(() => {
      // Construct the full URL using the current origin
      const fullUrl = new URL(userInfo.resultsurl)

      const pathAndQuery = fullUrl.pathname + fullUrl.search + fullUrl.hash;
      
      const navUrl = window.location.origin + pathAndQuery
      
      window.location.replace(navUrl);
    }, 3000);
  }

  return (
    <div className="results">
      <h1 className="top-title-text">
        Thank you for completing the Audicus Hearing Test!
      </h1>
      <p>
        You will also receive a copy of your results 
        by email within the next hour.
      </p>
      <div className="main-content">
        <p>
          Your results suggest 
          <strong>
            {' '}{classifyHearing(userInfo.vptaoverall)} hearing difficulties
          </strong>
        </p>
        <button
          onClick={() => handleViewResults()}
          className="link-button"
        >
          <p style={{ color: '#FFF', margin: 0 }}>
            {loading ? 'Calculating...' : 'See Detailed Results'}
          </p>
        </button>
      </div>
      <div className="test-info">
        <img src={greyCheck} alt='grey check mark' />
        <p>
          Over a decade ago, the <strong>Audicus Online Hearing Test</strong> became 
          the first of its kind. We've successfully issued 
          tens of 1000s of hearing assessments, changing countless 
          lives in the process. We take pride in the reputation 
          we've earned through the years as trusted industry experts. 
          Our <strong>96% satisfaction rating</strong> reflects a proven system you can 
          count on for better hearing.
        </p>
      </div>
    </div>
  );
}

export default Results;