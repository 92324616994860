import { useState } from "react";

import { classifyHearing, colorCodes } from "../utils";
import json from '../json/results.json';

import severeDial from '../assets/images/severe-difficulty.png';
import significantDial from '../assets/images/significant-difficulty.png';
import someDial from '../assets/images/some-difficulty.png';
import noDial from '../assets/images/no-difficulty.png';

import downArrow from '../assets/images/blue_down_arrow.png';

const diffLevels = {
  high: '(2,000 - 8,000 HZ)',
  mid: '(1,000 - 2,000 HZ)',
  low: '(500 - 1,000 HZ)',
};

const dialsMap = {
  severe: severeDial,
  significant: significantDial,
  some: someDial,
  no: noDial,
};

const EarResults = ({ side, data }) => {

  const [showDetails, setShowDetails] = useState({
    'Left': null,
    'Right': null,
  });

  const handleShowDetails = (side, index) => {
    let showDetailsCopy = { ...showDetails };
    if (showDetailsCopy[side] === index) {
      showDetailsCopy[side] = null;
    } else {
      showDetailsCopy[side] = index;
    }
    setShowDetails(showDetailsCopy);
  }

  const isSectionOpen = (side, index) => (
    (side === 'Left' && showDetails['Left'] === index) 
    || (side === 'Right' && showDetails['Right'] === index)
  );

  return (
    <div className="ear-result">
      <div className="ear-result-title">
        <h3>{side} Ear</h3>
      </div>
      <div className="left-right">

        <div className="left">
          <img 
            src={dialsMap[classifyHearing(data.overall)]} 
            alt="semicircle dial" 
          />
          <div className="left-text">
            <h3>{side} Ear Score</h3>
            <p>
              This is a summary of your hearing difficulties across low, mid, and high frequencies.
            </p>
          </div>
        </div>

        <div className="right">
          {Object.keys(diffLevels).map((level, i) => (
            <div className="frequency-info" key={`freq-${i}`}>

              <div style={{ display: 'flex' }}>
                <span style={{ marginBottom: '12px' }}>
                  <span style={{ fontFamily: 'Noto Sans Bold' }}>
                    {level.charAt(0).toUpperCase() + level.slice(1)} Frequencies
                  </span> 
                  {` ${diffLevels[level]}`} - 
                  <span
                    style={{ 
                      color: `${colorCodes[classifyHearing(data[level])]}`, 
                      marginLeft: '5px',
                      fontFamily: 'Noto Sans Bold',
                    }}
                  >
                    {classifyHearing(data[level]).toUpperCase()} DIFFICULTY
                  </span>
                </span>
              </div>

              {isSectionOpen(side, i) &&
                <div className="more-details">
                  <img 
                    src={dialsMap[classifyHearing(data[level])]} 
                    alt="semicircle dial" 
                  />
                  <p>
                    {json.explanations.find(obj => obj.type === level)[classifyHearing(data[level])]}
                  </p>
                </div>
              }

              <button
                className="details-button" 
                onClick={() => handleShowDetails(side, i)}
              >
                <p>{isSectionOpen(side, i) ? 'less' : 'more'} details</p>
                <img 
                  src={downArrow} 
                  alt="small blue down arrow"
                  style={isSectionOpen(side, i) ? { transform: 'rotate(180deg)' } : {}}
                />
              </button>
            </div>
          ))}

        </div>
      </div>
    </div>
  );
}

export default EarResults;
