import React, { createContext, useContext, useReducer } from 'react';

// Define the initial state of userInfo
const initialUserInfo = {
  contactid: null,
  patientid: null,
  email: null,
  resultsurl: null,
  overallscore: null,
  nameonresult: null,
  hearingresultid: null,
  hearingresultdata: {},
  vptaoverall: null,
  vptaleft: null,
  vptaright: null,
  notesabouthearingloss: null,
};

// Create a context
const UserInfoContext = createContext();

// Define a reducer to manage userInfo state
const userInfoReducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_CONTACTID':
      return { ...state, contactid: action.payload };
    case 'UPDATE_PATIENTID':
      return { ...state, patientid: action.payload };
    case 'UPDATE_EMAIL':
      return { ...state, email: action.payload };
    case 'UPDATE_NAMEONRESULT':
      return { ...state, nameonresult: action.payload };
    case 'UPDATE_RESULTSURL':
      return { ...state, resultsurl: action.payload };
    case 'UPDATE_OVERALLSCORE':
      return { ...state, overallscore: action.payload };
    case 'UPDATE_HEARINGRESULTID':
      return { ...state, hearingresultid: action.payload };
    case 'UPDATE_HEARINGRESULTDATA':
      return { ...state, hearingresultdata: action.payload };
    case 'UPDATE_VPTAOVERALL':
      return { ...state, vptaoverall: action.payload };
    case 'UPDATE_VPTALEFT':
      return { ...state, vptaleft: action.payload };
    case 'UPDATE_VPTARIGHT':
      return { ...state, vptaright: action.payload };
    case 'UPDATE_NOTESABOUTHEARINGLOSS':
      return { ...state, notesabouthearingloss: action.payload };
    case 'UPDATE_DOB':
        return { ...state, dob: action.payload };
    // Add more cases for other fields as needed
    default:
      return state;
  }
};

// Create a UserInfoProvider component to wrap your app with
export const UserInfoProvider = ({ children }) => {
  const [userInfo, dispatch] = useReducer(userInfoReducer, initialUserInfo);

  return (
    <UserInfoContext.Provider value={{ userInfo, dispatch }}>
      {children}
    </UserInfoContext.Provider>
  );
};

export const useUserInfo = () => {
  return useContext(UserInfoContext);
};
