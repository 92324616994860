import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import soundwaves from "../assets/images/soundwaves.png"
import Modal from '../components/Modal';
import volume from '../assets/images/half-volume-yellow.png';
import { useUserInfo } from '../context/UserInfoContext';
import { useNavigate } from 'react-router-dom';

const Instructions = ({ trackEvent, setProgress }) => {
  const [tracked, setTracked] = useState(false);
  const [modal, setModal] = useState(false);
  const { userInfo } = useUserInfo();
  const navigate = useNavigate();

  useEffect(() => {
    if (!userInfo.contactid) {
      return navigate('/demographics');
    }
    if (!tracked) trackEvent('JHT_TRAINING_START', userInfo.email);
    setTracked(true);
    setProgress();
  }, [tracked, setTracked, setProgress, trackEvent, userInfo, navigate]);

  const handleContinue = () => {
    setModal(true)
  }

  return (
    <div className="instructions">

    {modal && ( 
      <Modal closeModal={() => setModal(false)}> 
          <div className="volume-container">
            <img src={volume} alt="half volume" style={{ transform: 'scale(1.2)', marginBottom: '20px' }} />
          </div>
          <p className="device-modal-text" style={{ fontSize: '24px', lineHeight: '37px' }}>
            <strong>Do not</strong> adjust volume once <br/> test has begun.</p>
          <Link
            className="link-button" 
            to="/test"
            onClick={() => handleContinue()}
          >
            Continue
          </Link>
          
      </Modal>
    )}
    
      <h1 className="top-title-text">
        Let's do a training round to get you familiar
        with the test. Click 'Start Training' when you are 
        ready to begin!
      </h1>
      <div className="main-content">
        <p className="body-text"> 

        </p>

        <Link
          className="link-button" 
          // to="/slider"
          // to="/test"
          onClick={() => handleContinue()}
        >
          Start Training
        </Link>

        <img 
          className="soundwaves"
          src={soundwaves}
          alt="soundwaves background"
        />
      </div>
    </div>
  );
}

export default Instructions;