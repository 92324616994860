import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useUserInfo } from '../context/UserInfoContext';
import constants from '../constants';

const EMAIL_REGEX =
  /^(([^<>()[\].,;:\s@']+(\.[^<>()[\].,;:\s@']+)*)|('.+'))@(([^<>()[\].,;:\s@']+\.)+[^<>()[\].,;:\s@']{2,})$/i;
const PHONE_REGEX = /^\D*(\d\D*){7,14}$/;

const months = [
  { value: '01', label: 'Jan' },
  { value: '02', label: 'Feb' },
  { value: '03', label: 'Mar' },
  { value: '04', label: 'Apr' },
  { value: '05', label: 'May' },
  { value: '06', label: 'Jun' },
  { value: '07', label: 'Jul' },
  { value: '08', label: 'Aug' },
  { value: '09', label: 'Sep' },
  { value: '10', label: 'Oct' },
  { value: '11', label: 'Nov' },
  { value: '12', label: 'Dec' }
];

const currentYear = new Date().getFullYear();
const years = Array.from({ length: 100 }, (_, i) => {
  const year = currentYear - i - 17;
  return { value: year.toString(), label: year.toString() };
});
const days = Array.from({ length: 31 }, (_, i) => {
  const day = i + 1;
  return { value: day.toString().padStart(2, '0'), label: day.toString().padStart(2, '0') };
});

const Demographics = ({ trackEvent, setProgress, identifyUser }) => {
  const navigate = useNavigate();
  const { register, watch, setValue, formState: { errors }, handleSubmit, setError, clearErrors } = useForm({
    defaultValues: {
      month: '',
      day: '',
      year: '',
    }
  });


  const { dispatch } = useUserInfo();
  const [loading, setLoading] = useState(false);
  const [apiPostFailed, setApiPostFailed] = useState(false);
  const [tracked, setTracked] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {
    if (!tracked) {
      trackEvent('JHT_DEMOGRAPHICS_PAGE_LOAD', '');
      setTracked(true);
    }
    setProgress();
    if ('gaconnector2' in window) {
      window.gaconnector2.track("9d0dcebf01901722946f5eb3a3fee19a", {
        internalDomains: [
          "audicus.com",
          "audicushearingaids.typeform.com",
          "typeform.com",
          "unbounce.com",
          "pages.ubembed.com",
          "ubembed.com",
          "41cbdfe6d86e4ad4b837414dde1b562a.pages.ubembed.com"
        ]
      });
    }
    register('dob');
  }, [trackEvent, tracked, setTracked, setProgress, register]);
  
  useEffect(() => {
    const dob = `${watch('year')}-${watch('month')}-${watch('day')}`;
    setValue('dob', dob);
  }, [watch('month'), watch('day'), watch('year'), setValue]);

  const calculateAge = (dob) => {
    const dobDate = new Date(dob);
    const diff = Date.now() - dobDate.getTime();
    const ageDate = new Date(diff);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };

  async function hmacEncode(jsonStr, secret) {
    
    // Convert the JSON string and secret to Uint8Array using TextEncoder
    const encoder = new TextEncoder();
    const data = encoder.encode(jsonStr);
    const keyData = encoder.encode(secret);

    // Import the secret key for use with HMAC
    const key = await crypto.subtle.importKey(
      'raw',
      keyData,
      { name: 'HMAC', hash: { name: 'SHA-256' } },
      false,
      ['sign']
    );

    // Sign the data with HMAC-SHA-256
    const signature = await crypto.subtle.sign(
      'HMAC',
      key,
      data
    );

    // Convert the signature (ArrayBuffer) to a hexadecimal string
    return Array.from(new Uint8Array(signature))
      .map(b => b.toString(16).padStart(2, '0'))
      .join('');
  }

  const handleUpdateContactId = (contactid) => {
    dispatch({ type: 'UPDATE_CONTACTID', payload: contactid });
  };

  const handleUpdatePatientId = (patientid) => {
    dispatch({ type: 'UPDATE_PATIENTID', payload: patientid });
  };

  const handleUpdateNameOnResult = (nameonresult) => {
    dispatch({ type: 'UPDATE_NAMEONRESULT', payload: nameonresult });
  };

  const handleUpdateDOB = (dobFormatted) => {
    dispatch({ type: 'UPDATE_DOB', payload: dobFormatted });
  };


  const handleUpdateEmail = (email) => {
    dispatch({ type: 'UPDATE_EMAIL', payload: email });
  };

  const formData2Activity = (formData) => {
    const dobFormatted = `${formData.year}-${formData.month}-${formData.day}`;
    const contactData = {
      firstname: formData.firstname,
      lastname: formData.lastname,
      email: formData.email,
      phone: formData.phone,
    };
    const patientData = {
      first_name__c: formData.firstname,
      last_name__c: formData.lastname,
      date_of_birth__c: dobFormatted,
    };
    const activityData = {
      contact: contactData,
      patient__c: patientData,
      activitytypeid: 'a028Y00001FFCHGQA5'
    };
    return JSON.stringify(activityData);
  };

  const onSubmit = async (data) => {
    // console.log('called onSubmit')
    setFormSubmitted(true);
    if (!data.month || !data.day || !data.year) {
      setError('dob', { type: 'manual', message: 'Date of Birth is incomplete.' });
      return;
    } else {
      clearErrors('dob');
      const age = calculateAge(`${data.year}-${data.month}-${data.day}`);
      if (age < 18) {
        setError('dob', { type: 'manual', message: 'You must be at least 18 years old' });
        return;
      }
    }
    
    setIsButtonDisabled(true);
    setLoading(true);
    const activityData = formData2Activity(data);
    const signature = await hmacEncode(activityData, constants['hmacSecret']);
    // console.log('activityData:', activityData);
    // console.log('signature:', signature);
    
    try {
      const response = await fetch(constants['apiBaseUrl'], {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-AUD-Signature': signature,
        },
        body: activityData,
      });
      const responseData = await response.json();
      // console.log('responseData', responseData);
      setLoading(false);
      if (!responseData.contact.id) {
        setApiPostFailed(true);
        setIsButtonDisabled(false);
      } else {
        // Handle successful response
        const contactId = responseData.contact.id;
        const patientId = responseData.patient__c.id;
        handleUpdateContactId(contactId);
        handleUpdatePatientId(patientId);
        handleUpdateEmail(data.email);
        handleUpdateDOB(`${data.year}-${data.month}-${data.day}`);
        const fullName = `${data.firstname} ${data.lastname}`;
        handleUpdateNameOnResult(fullName);
        if (process.env.REACT_APP_USE_SKIP_SEGMENT === 'false') {
          identifyUser(contactId, data.email, data.firstname, data.lastname);
        }
        trackEvent('JHT_SUBMIT_DEMOGRAPHICS', data.email);
        navigate('/device');
      }
    } catch (error) {
      setLoading(false);
      setApiPostFailed(true);
      setIsButtonDisabled(false);
      console.error('Error posting data:', error);
    }
  };

  return (
    <div className="demographics">
      <h1 className="top-title-text">First, tell us about yourself</h1>
      <form className="form-content" onSubmit={handleSubmit(onSubmit)}>

          <label htmlFor="firstname">First Name*</label>
          <input className={errors.firstname ? "field-error" : ""} 
          {...register('firstname', { required: 'First Name is required' })} />
          <p className="error-text">
          {errors.firstname && errors.firstname.message}
          </p>

          <label htmlFor="lastname">Last Name*</label>
          <input className={errors.lastname ? "field-error" : ""} 
          {...register('lastname', { required: 'Last Name is required' })} />
          <p className="error-text">
          {errors.lastname && errors.lastname.message}
          </p>

          <label htmlFor="email">Email*</label>
          <input className={errors.lastname ? "field-error" : ""} 
          {...register('email', {
              required: 'Email Address is required',
              pattern: { value: EMAIL_REGEX, message: 'Please enter a valid email address' }
          })} aria-invalid={errors.email ? 'true' : 'false'} />
          <p className="error-text">
          {errors.email && errors.email.message}
          </p>

          <label htmlFor="phone">Phone*</label>
          <input className={errors.lastname ? "field-error" : ""} 
          {...register('phone', {
              required: 'Phone Number is required',
              pattern: { value: PHONE_REGEX, message: 'Please enter a valid phone number' }
          })} aria-invalid={errors.phone ? 'true' : 'false'} />
          <p className="error-text">
          {errors.phone && errors.phone.message}
          </p>

          <label htmlFor="phone">Date of Birth*</label>
          <div style={{ display: 'flex', gap: '10px' }}>

            <select className={formSubmitted && !watch('month') ? "field-error" : ""}
                    style={{width: '40%'}}
                    {...register('month', 
                    { onChange: () => setFormSubmitted(false) })}>
              <option value="" disabled selected>Month</option>
              {months.map((option) => (
                <option key={option.value} value={option.value}>{option.label}</option>
              ))}
            </select>

            <select className={formSubmitted && !watch('day') ? "field-error" : ""} 
                    style={{width: '24%'}}
                    {...register('day', 
                    { onChange: () => setFormSubmitted(false) })}>
              <option value="" disabled selected>Day</option>
              {days.map((option) => (
                <option key={option.value} value={option.value}>{option.label}</option>
              ))}
            </select>

            <select className={formSubmitted && !watch('year') ? "field-error" : ""}
                    style={{width: '36%'}}
                    {...register('year',
                    { onChange: () => setFormSubmitted(false) })}>
              <option value="" disabled selected>Year</option>
              {years.map((option) => (
                <option key={option.value} value={option.value}>{option.label}</option>
              ))}
            </select>
          </div>
          <p className="error-text">
            {errors.dob && errors.dob.message}
          </p>
          <p className="consent-text">
          By clicking SUBMIT, you agree to receive marketing text messages from Audicus, including messages sent by autodialer.
          Consent is not a condition of any purchase. Message and data rates may apply. Message frequency varies. Reply HELP for 
          help or STOP to cancel. View our <a href="https://audicus.com/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a> and <a href="https://audicus.com/terms-of-service" target="_blank" rel="noopener noreferrer">Terms of Service</a> for more.
          </p>
        <input
          type="submit"
          className="link-button"
          value={loading ? 'Submitting...' : 'Submit'}
          disabled={isButtonDisabled}
        />
      </form>
    </div>
  );
};

export default Demographics;
