import audicusLogo from '../assets/images/audicus-logo.png';

import { useLocation } from 'react-router';

const Header = () => {
  const { pathname } = useLocation();
  if (pathname !== '/') {
    return (
      <div className="header">
        <img src={audicusLogo} alt="audicus logo" />
      </div>
    );
  } else {
    return <></>;
  }
};

export default Header;