// list of ear-frequencies in order that they will be tested
// each element determines the set of audio files to load for the slider and test screens
const freqList = ['R1000', 'R500', 'R2000', 'R3000', 'R4000', 'R8000', 
  'L1000', 'L500', 'L2000', 'L3000', 'L4000', 'L8000'];

// DEV
// const freqList = ['R1000', 'R500']

// random time interval between successive stimuli during test sequence
// uniform interval defined by these parameters (ms)

// dev values
// const intervalLower = 50;
// const intervalUpper = 100;

// production values
const intervalLower = 2000;
const intervalUpper = 4000;

// if user response occurs within this many ms after stimulus start, do not count it as a Hit
const falseAlarmThreshold = 150;

// if user has a Hit at <=5 dB this many times, call 5 dB their threshold
const numHits5Threshold = 2;
// if user has a miss at 85 dB this many times, call 85 their threshold
const numMisses85Threshold = 2;

// maximum number of trials in a sequence before best guess is made at threshold
const maxTrials = 20;

const hmacSecret = 'cEPmy1ts84!dlPvTmc16c%Jq6%g7%t35WYEZmHru7CW*akBpO%Y52!NpF9';

// audicus api requests go through this link
const apiBaseUrl = 'https://api.audicus.com/sfprod/activity?debug=true';

// relative path to be used with proxy
// const apiBaseUrl = '/sfprod/activity?debug=true';

// to avoid CORS error and write data to Salesforce during dev, add this line to package.json
// "proxy": "https://dev---audicusapi-r4jkhsdnsq-uk.a.run.app"


const constants = {
  'freqList' : freqList,
  'intervalLower' : intervalLower,
  'intervalUpper' : intervalUpper,
  'falseAlarmThreshold' : falseAlarmThreshold,
  'numHits5Threshold' : numHits5Threshold,
  'numMisses85Threshold' : numMisses85Threshold,
  'maxTrials' : maxTrials,
  'apiBaseUrl' : apiBaseUrl,
  'hmacSecret' : hmacSecret
}      

export default constants;