import { useSearchParams } from "react-router-dom";

import redArrow from '../assets/images/red-arrow.png';
import { classifyHearing, colorCodes } from "../utils";

import constants from '../constants'

import EarResults from "../components/EarResults";

const DetailedResults = () => {
  const [searchParams] = useSearchParams();
  const date = new Date(Number(searchParams.get('date')));
  const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
  const dateFormatted = date.toLocaleDateString('en', options);

  const name = searchParams.get('name');
  const results = JSON.parse(searchParams.get('results'));
  
  const mapIndicatorPosition = (overallValue) => {
    if (overallValue <= 25) return 0;
    if (overallValue <= 40) return 25;
    if (overallValue <= 70) return 50;
    return 75;
  }

  async function hmacEncode(jsonStr, secret) {
    
    // Convert the JSON string and secret to Uint8Array using TextEncoder
    const encoder = new TextEncoder();
    const data = encoder.encode(jsonStr);
    const keyData = encoder.encode(secret);

    // Import the secret key for use with HMAC
    const key = await crypto.subtle.importKey(
      'raw',
      keyData,
      { name: 'HMAC', hash: { name: 'SHA-256' } },
      false,
      ['sign']
    );

    // Sign the data with HMAC-SHA-256
    const signature = await crypto.subtle.sign(
      'HMAC',
      key,
      data
    );

    // Convert the signature (ArrayBuffer) to a hexadecimal string
    return Array.from(new Uint8Array(signature))
      .map(b => b.toString(16).padStart(2, '0'))
      .join('');
  }
  
  const callAndPostData = async () => {

    const contactData = {
      id: searchParams.get('contactid') 
    };
    // initialize the activityData
    const data = { 
      contact: contactData,
      activitytypeid: 'a02Ud000001i9lOIAQ' // JHT Web Results Call
    };

    const activityData = JSON.stringify(data);
    const signature = await hmacEncode(activityData, constants['hmacSecret']);
    // console.log('signature:', signature);
    // console.log('activityData:', activityData);

    try {
      const response = await fetch(constants['apiBaseUrl'], {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-AUD-Signature': signature,
        },
        body: activityData,
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      // Handle successful response here
    } catch (error) {
      console.error('Error during API call:', error);
    }
  };

  return (
    <div className="detailed-results">
      <p className="name-date">
        Prepared for {name + ' on ' + dateFormatted}.
      </p>
      <h1>Your results suggest</h1>
      <h2>{classifyHearing(results.overall)} hearing difficulty</h2>

      <div className="loss-indicator loss-indicator-position">
        <div style={{ marginRight: `${mapIndicatorPosition(results.overall)}%` }}>
          <p>Your overall hearing</p>
          <img src={redArrow} alt="red arrow" />
        </div>
      </div>

      <div className="top-severity-bar">
        {Object.keys(colorCodes).map((code, i) => (
          <div className="bar-section" key={`bar-${i}`}>
            <div style={{ background: `${colorCodes[code]}` }} />
            <p>{code.charAt(0).toUpperCase() + code.slice(1)} difficulty</p>
          </div>
        ))}
      </div>

      <EarResults side={'Left'} data={results.L} />
      <EarResults side={'Right'} data={results.R} />

      <div className="bottom-buttons">
          <a
            className="shop-button"
            href="https://www.audicus.com/best-hearing-aids"
          >
            Shop Hearing Aids
          </a>
          <a
            className="call-button"
            href="tel:888-203-1096,"
            onClick={callAndPostData}
            style={{ background: '#b55821' }}
          >
            Call Audicus Specialist
          </a>
      </div>

      <div className="bottom-info">
        <p>
          While we’ve administered thousands of hearing tests, we 
          value your feedback to help us make it even better. If 
          you have feedback on our hearing test, please send it to 
          {' '}
          <a 
            style={{ color: 'black', fontFamily: 'Noto Sans Bold' }} 
            href="mailto:research@audicus.com"
          >
            research@audicus.com.
          </a>
        </p>
      </div>
    </div>
  );
}

export default DetailedResults;