import { Link } from 'react-router-dom'
import soundwaves from '../assets/images/soundwaves.png';
import playButton from '../assets/images/play-button.png';
import { useState, useEffect } from 'react';
import audioData from '../assets/audio/index';
import Modal from '../components/Modal';
import unchecked from '../assets/images/checkbox-unselected.png';
import { useUserInfo } from '../context/UserInfoContext';
import { useNavigate } from 'react-router-dom';

const Headphones = ({ trackEvent, setProgress }) => {
  const [playing, setPlaying] = useState(false);
  const [canAdvance, setCanAdvance] = useState(false);
  const [modal, setModal] = useState(false);
  const [testSoundPlayed, setTestSoundPlayed] = useState(false);

  const [leftSelected, setLeftSelected] = useState(false);
  const [rightSelected, setRightSelected] = useState(false);

  const [errorState, setErrorState] = useState(false);
  const [tracked, setTracked] = useState(false);
  const { userInfo } = useUserInfo();
  const navigate = useNavigate();

  useEffect(() => {
    if (!userInfo.contactid) {
      return navigate('/demographics');
    }
    if (!tracked) trackEvent('JHT_CHECK_HEADPHONES_PAGE_LOAD', userInfo.email);
    setTracked(true);
    setProgress();
  }, [tracked, setTracked, setProgress, trackEvent, userInfo, navigate]);

  const handlePlaySound = () => {
    setErrorState(false);
    setTestSoundPlayed(true);
    setPlaying(true);
  }

  const handleRightButtonClick = () => {
    if (!testSoundPlayed) {
      setErrorState(true);
      return;
    }
    setRightSelected(true);
    setLeftSelected(false);
    setCanAdvance(true);
  }

  const handleLeftButtonClick = () => {
    if (!testSoundPlayed) {
      setErrorState(true);
      return;
    }
    setLeftSelected(true);
    setRightSelected(false);
    setModal(true);
    setCanAdvance(false);
  }

  return (
    <div className="headphones">

      {modal && ( 
        <Modal closeModal={() => setModal(false)}> 
          <div style={{ padding: '40px 10px' }}>
            <p 
              style={{
                fontSize: 20, 
                fontFamily: 'Georgia', 
                textAlign: "center",
                lineHeight: '25px',
              }}
            > 
              We've sent sound to your right ear, but you indicated
              you heard the sound in your left ear.
              Check to make sure your headphones are in the correct ear. You
              may need to switch them around.
            </p>
          </div>
        </Modal>
      )}

      <h1 className="top-title-text">
        Let's Make Sure Your Headphones Are Working Correctly
      </h1>
      <div className="main-content">
        <img 
          className="soundwaves"
          src={soundwaves} 
          alt='sound waves' 
        />
        {playing ? 
          <audio 
            src={audioData['testSoundRight']} 
            autoPlay 
            onEnded={() => setPlaying(false)} 
          /> 
          : 
        <>
          <button 
            className="play-button" 
            onClick={() => handlePlaySound()}
          >
            <img src={playButton} alt="Play Sound" />
          </button>
        </>   
        }
      </div>
      <p>Click the white play button to play a sample sound. Which ear do you hear the sound in?</p>
      {errorState ? 
        <p style={{color: "#FF0000"}}>Play the test sound first before selecting an ear</p>
        :
        <></>
      }
      <div className="left-right-buttons">
        <button 
          className={
            leftSelected && !rightSelected 
              ? 'left-button-selected' 
              : ''} 
          onClick={() => handleLeftButtonClick()}
        >
          left ear
        </button>
        <button 
          className={
            rightSelected && !leftSelected 
              ? 'right-button-selected' 
              : ''} 
          onClick={() => handleRightButtonClick()}
        >
          right ear 
        </button>
      </div>
      <Link 
        className={`
          link-button
          ${!canAdvance ? 'link-button-disabled' : ''}
        `}
        to="/instructions"
        style={!canAdvance ? { background: 'darkgrey' } :  {}}
      >
        Continue
      </Link>
    </div>
  );
}

export default Headphones;

