import { Link } from 'react-router-dom';
import blurred from "../assets/images/soundwaves-blur.png";
import logo from '../assets/images/audicus-logo-white.png';
import { useEffect } from 'react';

const Home = ({ trackEvent }) => {
  useEffect(() => trackEvent('JHT_VIEW_LANDING', ''));
  const versionId = process.env.REACT_APP_GIT_COMMIT || 'dev'

  return (
    <div className="home">
      <div className="main-content">
        <div className="version-container">
          <p style={{color: 'white', fontSize: '2px'}}> {versionId} </p>
        </div>
        <img 
          className="audicus-logo-white"
          src={logo} 
          alt="audicus logo" 
        />
        <div className="title-container">
          <h1>H E A R I N G</h1>
          <h1>T E S T</h1>
        </div>
        <div className="description">
          <p>The original and best digital hearing test</p>
          <p style={{ color: 'white' }}>Only takes 10 minutes</p>
        </div>
        <Link
          to="/demographics"
          className="link-button"
        >
          Start Hearing Test
        </Link>
        <div className="description">
          <a href="https://audicus.com/submit-test" 
             style={{ color: 'white', fontSize: '18px', fontFamily: 'Noto Sans' }}
             target="_blank" 
             rel="noopener noreferrer"
          >
             I already have a test
          </a>
        </div>
      </div>
      <img 
        className="soundwaves"
        src={blurred}
        alt="soundwaves background"
      />
      {/* <p>
        Home screen info text
      </p> */}
    </div>
  );
}

export default Home;


