import close from '../assets/images/close-icon.png';

const Modal = ({ closeModal, children }) => {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        {closeModal && (
          <button
            className="close-modal-button"
            onClick={() => closeModal()}
          >
            <img src={close} alt="close modal" />
          </button>
        )}
        {children}
      </div>
    </div>
  );
}

export default Modal;